import { colors, dimensions, typography } from '#styles'
import styled from 'styled-components'

import CloseIcon from '../../../public/assets/Franchise/close-icon.svg'

export const FAQArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Header = styled.h1`
  ${typography.displayMd.bold};
  color: ${colors.neutral900};
`

export const SubTitle = styled.h1`
  ${typography.bodyMd.regular};
  color: ${colors.neutral900};
`

export const FAQContainer = styled.div`
  width: 100%;
  @media (min-width: ${dimensions.laptop}) {
    width: 65%;
  }
`

export const QuestionWrapper = styled.details`
  padding: 3rem 0.5rem;
  border-bottom: 1px solid ${colors.neutral400};
  position: relative;
  letter-spacing: 1px;
  line-height: 1.6rem;

  &[open] {
    border-color: ${colors.redTorch};
  }

  &[open] > summary::after {
    transform: rotate(-90deg);
  }

  & > summary::-webkit-details-marker,
  & > summary::marker {
    display: none;
  }
`

export const Question = styled.summary`
  cursor: pointer;
  ${typography.bodyLg.medium}
  color: ${colors.black};
  margin-bottom: 1rem;
  max-width: 85%;
  color: ${colors.neutral700};
  gap: 1rem;
  list-style: none;

  &::after {
    content: '';
    position: absolute;
    top: 45px;
    right: 0;
    width: 24px;
    height: 24px;
    background-image: url(${CloseIcon.src});
    background-repeat: no-repeat;
    font-size: 2rem;
    font-weight: 100;
    transform-origin: center;
    transform: rotate(-45deg);
    transition: transform 0.4s ease;
  }
`
export const Answer = styled.article`
  line-height: 2rem;
  white-space: pre-line;
  color: ${colors.neutral600};
`
