import { Trans } from 'next-i18next'
import React, { FC, ReactNode } from 'react'

import { colors } from '#styles'

import { Links } from '../../lib/constants'
import { getAppLink } from '../../lib/helpers'
import * as S from './styled'

type Props = {
  faqs: {
    body: string
    header: string
  }[]
  children?: ReactNode
  locale?: string
  applessOnly: boolean
}

export const textLink = (href: string) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      style={{ color: colors.blueAccent }}
    />
  )
}

const FAQ: FC<Props> = ({ faqs, children, applessOnly }) => {
  return (
    <S.FAQContainer>
      {children && <S.FAQArticle>{children}</S.FAQArticle>}
      {faqs.map((faq, idx) => (
        <S.QuestionWrapper key={faq.header} id={`faq_${idx + 1}`}>
          <S.Question>{faq.header}</S.Question>
          <S.Answer>
            <Trans
              defaults={faq.body}
              components={{
                appLink: textLink(getAppLink(applessOnly)),
                brickApp: textLink(Links.YOUTUBE.RENT_WITH_APP),
                appLess: textLink(Links.YOUTUBE.RENT_WITH_APPLESS),
                formLink: textLink(Links.MONDAY_STATIONS),
                sixLink: textLink(Links.YOUTUBE.SIX_SLOT),
                twelveLink: textLink(Links.YOUTUBE.TWELVE_SLOT),
              }}
            />
          </S.Answer>
        </S.QuestionWrapper>
      ))}
    </S.FAQContainer>
  )
}

export default FAQ
